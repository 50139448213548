import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

interface MembersAreaPublicApi {
  openGeneralSettingsPanel: () => Promise<void>;
}

const getMembersAreaApi = (editorSDK: FlowEditorSDK) => {
  return editorSDK.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA,
  }) as Promise<MembersAreaPublicApi>;
};

export const openGeneralSettingsPanel = async (editorSDK: FlowEditorSDK) => {
  const membersAreaApi = await getMembersAreaApi(editorSDK);
  return membersAreaApi.openGeneralSettingsPanel();
};
